.land-reaserch {
    .modal-content {
        .modal-header {
            display: block;
            padding: 1rem 1rem;
            border: none;
            .back-btn {
                margin-right: 20px;
                height: 46px;
                width: 46px;
                font-size: 26px;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .title {
                h2 {
                    font-size: 42px;
                    font-weight: 500;
                }
            }
        }
        .modal-body {
            padding: 30px 1rem;
        }
        .modal-footer {
            flex-direction: column;
            border: none;
            padding-top: 2rem;
            padding-bottom: 2rem;
            .reserver-btn {
                font-size: 18px;
                padding-top: 22px;
                padding-bottom: 22px;
                border-radius: 25px;
            }
        }
    }
}

@media only screen and (min-width: 2600px) {
    .land-reaserch {
        .modal-content {
            .modal-header {
                padding: 1rem 1rem;
                .back-btn {
                    height: 96px;
                    width: 96px;
                    font-size: 50px;
                }
                .title {
                    h2 {
                        font-size: 64px;
                    }
                }
            }
            .modal-body {
                margin-top: 10rem;
            }
            .modal-footer {
                padding-top: 5rem;
                padding-bottom: 5rem;
                .reserver-btn {
                    font-size: 40px;
                    padding-top: 52px;
                    padding-bottom: 52px;
                    border-radius: 46.634px;
                }
            }
        }
    }
}