.footer {
    position: fixed;
    bottom: 5%;
    text-align: center;
    transform: translateX(-50%);
    left: 50%;
    z-index: 10;
    ul {
        background-color: #ffffff;
        box-shadow: 0px 0px 60.6218px rgba(0, 0, 0, 0.2);
        border-radius: 80px;
        display: inline-flex;
        padding: 22px 30px;
        list-style: none;
        margin-bottom: 0;
        li {
            display: block;
            text-align: center;
            font-size: 14px;
            margin: 0 24px;
            cursor: pointer;
            .icon {
                min-height: 27px;
            }
            p {
                color: #C1C1C1;
                margin-bottom: 0;
                margin-top: 6px;
            }
            &:hover {
                .icon {
                    filter: brightness(0) saturate(100%) invert(61%) sepia(19%) saturate(1253%) hue-rotate(327deg) brightness(86%) contrast(85%);
                }
                p {
                    color: #11151E;
                }
            }
            &.active {
                .icon {
                    filter: brightness(0) saturate(100%) invert(61%) sepia(19%) saturate(1253%) hue-rotate(327deg) brightness(86%) contrast(85%);
                }
                p {
                    color: #11151E;
                }
            }
        }
    }
}

@media only screen and (min-width: 2600px) {
    .footer {
        ul {
            padding: 45px 60px;
            li {
                font-size: 14px;
                margin: 0 55px;
                .icon {
                    color: #C1C1C1;
                    font-size: 36px;
                }
                p {
                    margin-top: 10px;
                    font-size: 26px;
                }
            }
        }
    }
}