.view-land {
  .modal-dialog {
    .modal-content {
      padding: 30px 20px;
      border-radius: 30px;
      &::after {
        content: "";
        // background-image: url(../../assets/images/modal_tringle.png);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        height: 31px;
        width: 130px;
        position: absolute;
        bottom: 2px;
        transform: translate(-50%, 100%);
        left: 50%;
      }
    }
    .modal-header {
      border-bottom: 2px dashed #f0f0f0;
      padding: 0 0 30px 0;
      .close-button {
        padding: 0px !important;
        margin: 0px !important;
        // width: nul;
      }
    }
    .modal-body {
      padding: 14px 0 30px 0;
      .title {
        display: flex;
        justify-content: center;
        align-items: center; 
        margin-bottom: 19px;
      }
      .main-title {
        font-size: 28px;
        font-weight: 500;
        text-align: center;
        margin-right: 5px;
        margin-bottom: -5px
      }
      .star-button {
        box-shadow: none;
        margin-top: 5px;
        padding-bottom: 5px;
        padding-top: 0px;
        padding-left: 6px;
        padding-right: 6px;
      }
      .star {
        color: #CBAF99;
        font-size: 20px;
        margin: 0;
        // margin-left: 8px;
      }
      .sub-title {
        color: #c1c1c1;
        font-size: 16px;
        font-weight: 300;
        text-align: center;
      }
      .reserved-text {
        margin-top: 31px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        .icon {
          margin-right: 8px;
        }
        span {
          font-weight: 300;
        }
      }
    }
    .modal-footer {
      border: none;
      padding: 0;
      .view-more-btn {
        width: 100%;
        font-size: 18px;
        font-weight: 600;
        padding-top: 22px;
        padding-bottom: 22px;
        border-radius: 25px;
      }
    }
  }
}

@media only screen and (min-width: 2200px) {
  .view-land {
    .modal-dialog {
      max-width: 714px;
      .modal-content {
        padding: 48px 45px;
        border-radius: 40px;
        &::after {
          content: "";
          height: 31px;
          width: 130px;
        }
      }
      .modal-header {
        padding: 0 0 60px 0;
        border-width: 4.5px;
      }
      .modal-body {
        padding: 30px 0 40px 0;
        .main-title {
          font-size: 68px;
          margin-bottom: 32px;
        }
        .sub-title {
          font-size: 36px;
        }
        .reserved-text {
          margin-top: 48px;
          .icon {
            margin-right: 18px;
            font-size: 30px;
          }
          span {
            font-weight: 300;
            font-size: 30px;
          }
        }
      }
      .modal-footer {
        .view-more-btn {
          font-size: 40px;
          padding-top: 50px;
          padding-bottom: 50px;
          border-radius: 50px;
        }
      }
    }
  }
}
