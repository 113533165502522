.view-more-modal {
    .modal-dialog {
        .modal-content {
            padding: 30px 20px;
            border-radius: 30px;
            &::after {
                content: '';
                // background-image: url(../../assets/images/modal_tringle.png);
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                height: 31px;
                width: 130px;
                position: absolute;
                bottom: 2px;
                transform: translate(-50%, 100%);
                left: 50%;
            }
        }
        .modal-header {
            border-bottom: 2px dashed #F0F0F0;
            padding: 0 0 30px 0;
        }
        .modal-body {
            padding: 14px 0 31px 0;
            .phase-title {
                font-size: 18px;
                font-weight: 400;
                margin-bottom: 32px;
            }
            .phase-details {
                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    li {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        margin-bottom: 20px;
                        .text {
                            font-size: 16px;
                            font-weight: 300;
                        }
                        .count {
                            font-size: 20px;
                            color: #CE785E;
                            margin-left: auto;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
        .modal-footer {
            border: none;
            padding: 0;
            .view-more-btn {
                width: 100%;
                font-size: 18px;
                font-weight: 600;
                padding-top: 22px;
                padding-bottom: 22px;
                border-radius: 50px;
            }
        }
    }
}

.progress {
    background-color: #F0F0F0;
    border-radius: 50px;
    .progress-bar {
        border-radius: 50px;
    }
}

@media only screen and (min-width: 2200px) {
    .view-more-modal {
        .modal-dialog {
            max-width: 714px;
            .modal-content {
                padding: 48px 45px;
                border-radius: 40px;
                &::after {
                    content: '';
                    height: 31px;
                    width: 130px;
                }
            }
            .modal-header {
                padding: 0 0 60px 0;
                border-width: 4.5px;
            }
            .modal-body {
                padding: 30px 0 60px 0;
                .phase-title {
                    font-size: 40px;
                    margin-bottom: 32px;
                }
                .phase-details {
                    ul {
                        li {
                            margin-bottom: 45px;
                            .text {
                                font-size: 36px;
                            }
                            .count {
                                font-size: 45px;
                                color: #CE785E;
                            }
                        }
                    }
                }
            }
            .modal-footer {
                .view-more-btn {
                    font-size: 40px;
                    padding-top: 50px;
                    padding-bottom: 50px;
                    border-radius: 50px;
                }
            }
        }
    }
    .progress {
        height: 22px;
    }
}