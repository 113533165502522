.favourite-page {
    .modal-header {
        border-bottom: none;
        .col-first {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .back-btn {
                font-size: 24px;
                color: #CE785E;
                background: #FFFFFF;
                border-radius: 50%;
                height: 50px;
                width: 50px;
                padding: 14px;
                cursor: pointer;
            }
        }
        .col-second {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .col-third {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .toggle-btn {
                font-size: 20px;
                color: #11151E;
                background: #FFFFFF;
                box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
                border-radius: 50%;
                height: 50px;
                width: 50px;
                padding: 14px;
                cursor: pointer;
            }
        }
    }
    .modal-body {
        .top {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: 30px;
        }
    }
}

.area-image {
    svg {
        width: 100%;
        height: 130px;
        object-fit: contain;
        object-position: center;
    }
}

.favourite-single {
    border: none;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    background-color: #fff;
    overflow: hidden;
    margin-bottom: 10px;
    .dimension {
        color: #11151E;
        font-size: 17px;
        font-weight: 300;
        margin: 0 20px 0 0;
    }
    .card-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        border: none;
        padding-top: 30px;
        background-color: inherit;
        .item-name {
            margin-bottom: 0;
            font-size: 28px;
            span {
                font-size: 18px;
                font-weight: 300;
            }
        }
        .star-button {
            padding-bottom: 5px;
            padding-top: 0px;
            padding-left: 6px;
            padding-right: 6px;
          }
          .star {
            color: #CBAF99;
            font-size: 20px;
            margin: 0;
          }
    }
    .card-body {
        padding-top: 0;
        .card-center {
            margin-bottom: 14px;
            .phase-count {
                margin-bottom: 16px;
            }
            // .timer{
            //     display: flex;
            //     width: fit-content;
            //     padding: 16px 20px;
            //     border-radius: 35px;
            //     border: 2px solid #E58888;
            //     color: #E58888;
            //     align-items: center;
            //     font-weight: 500;
            //     font-size: 16px;
            //     svg{
            //         margin-right: 10px;
            //     }
            //     &.disable{
            //         color: #DEDEDE;
            //         border-color: #DEDEDE;
            //         pointer-events: none;
            //     }
            // }
        }
        .card-bottom {
            margin-top: 30px;
            .see-land-btn {
                width: 100%;
                padding-top: 22px;
                padding-bottom: 22px;
                border-radius: 25px;
                &.disable {
                    background: #DEDEDE;
                }
            }
        }
    }
}

@media only screen and (min-width: 576px) {
    .favourite-page {
        .modal-header {
            .col-first {
                .back-btn {
                    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
                }
            }
        }
    }
}

@media only screen and (min-width: 2600px) {
    .favourite-page {
        .modal-header {
            .col-first {
                .back-btn {
                    font-size: 40px;
                    height: 80px;
                    width: 80px;
                    padding: 12px;
                }
            }
            .col-third {
                .toggle-btn {
                    font-size: 40px;
                    height: 80px;
                    width: 80px;
                    padding: 22px;
                }
            }
        }
        .modal-body {
            .top {
                margin-bottom: 60px;
                h5 {
                    font-size: 44px;
                }
                p {
                    font-size: 28px;
                }
            }
        }
    }
    .favourite-single {
        border-radius: 50px;
        margin-bottom: 20px;
        .dimension {
            font-size: 30px;
        }
        .card-header {
            padding-top: 60px;
            .item-name {
                margin-bottom: 0;
                font-size: 60px;
                span {
                    font-size: 30px;
                }
            }
            .star {
                font-size: 34px;
            }
        }
        .card-body {
            .card-center {
                margin-bottom: 30px;
                .phase-count {
                    margin-bottom: 30px;
                }
                // .timer{
                //     padding: 30px 40px;
                //     border-radius: 50px;
                //     border-width: 4px;
                //     font-size: 30px;
                //     svg{
                //         margin-right: 20px;
                //     }
                // }
            }
            .card-bottom {
                .see-land-btn {
                    font-size: 30px;
                    padding-top: 40px;
                    padding-bottom: 40px;
                    border-radius: 40px;
                }
            }
        }
    }
}