.submit-details {
    .modal-content {
        .modal-header {
            display: block;
            padding: 1rem 1rem;
            border: none;
            .back-btn {
                margin-right: 20px;
                height: 46px;
                width: 46px;
                font-size: 26px;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .title {
                h2 {
                    font-size: 42px;
                    font-weight: 500;
                }
            }
        }
        .modal-body {
            .col-title {
                margin-bottom: 60px;
                font-size: 28px;
                font-weight: 400;
            }
            .reserver-btn {
                font-size: 18px;
                padding-top: 22px;
                padding-bottom: 22px;
                border-radius: 25px;
            }
        }
    }
}

.footer-submit-details {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 24px 0;
    background-color: #ffffff;
    z-index: 1;
    left: 0;
    right: 0;
    .footer-wraper {
        padding: 0 14px;
        &.second {
            padding: 0 0px;
        }
    }
}

.tab-sec-main {
    >.nav {
        padding: 0px 0px 12px 0px;
        border-bottom: 1px dashed #E4E4E4;
        >.nav-item {
            >.nav-link {
                color: #f1f1f1;
                font-size: 20px;
                font-weight: 500;
                padding: .5rem 1rem .5rem 0rem;
                &.active {
                    color: #708468;
                }
            }
        }
    }
    >.tab-content {
        padding: 12px 0px;
    }
}

.tab-sec-sub {
    >.nav {
        padding: 0px 0px 12px 0px;
        >.nav-item {
            >.nav-link {
                color: #c1c1c1;
                font-size: 18px;
                font-weight: 500;
                padding: 6px 0;
                margin-right: 21px;
                border-bottom: 2px solid transparent;
                &.active {
                    color: #11151E;
                    border-color: #CE785E;
                }
            }
        }
    }
    >.tab-content {
        padding: 12px 0px;
    }
}

.single-person {
    border-radius: 25px;
    border: 1px dashed #E4E4E4;
    padding: 22px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .left {
        display: flex;
        align-items: center;
        .person-icon {
            color: #B1B1B1;
            font-size: 30px;
            margin-right: 12px;
        }
        .person-detail {
            .person-id {
                color: #B1B1B1;
                font-size: 9px;
                display: block;
            }
            .person-name {
                color: #11151E;
                font-size: 16px;
                display: block;
            }
        }
    }
    .right {
        .person-edit {
            color: #708468;
            font-size: 24px;
            // margin-right: 12px;
        }
        .person-close {
            color: #CE785E;
            font-size: 24px;
        }
    }
}

.payment-points {
    .points {
        margin-bottom: 26px;
        .points-top {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            margin-bottom: 10px;
            .points-icon {
                color: #708468;
                font-size: 20px;
                margin-right: 10px;
                flex: 0 0 auto;
            }
            .text {
                color: #11151E;
                font-size: 18px;
                margin-bottom: 0;
            }
        }
        .points-bottom {
            .text {
                color: #C1C1C1;
                font-size: 16px;
                margin-bottom: 0;
            }
        }
    }
}

.tab-1 {
    .tab-1-a {
        padding-bottom: 70px;
    }
    .tab-1-b {
        padding-bottom: 150px;
    }
}

.tab-2 {
    padding-bottom: 74px;
}

.limit-warning {
    color: #DDDDDD;
    margin: 10% 0;
    text-align: center;
}

@media only screen and (min-width: 768px) {
    .tab-sec-main {
        >.nav {
            padding: 38px 20px;
        }
        >.tab-content {
            padding: 58px 20px;
        }
    }
    .tab-sec-sub {
        >.nav {
            padding: 0px 0px 34px 0px;
        }
        >.tab-content {
            padding: 60px 0px;
        }
    }
    .footer-submit-details {
        .footer-wraper {
            padding: 0 34px;
            &.second {
                padding: 0 24px;
            }
        }
    }
    .payment-points {
        .points {
            margin-bottom: 36px;
            .points-top {
                margin-bottom: 16px;
                .points-icon {
                    margin-right: 10px;
                }
                .text {
                    font-size: 18px;
                }
            }
        }
    }
}

@media only screen and (min-width: 1200px) {
    .footer-submit-details {
        .footer-wraper {
            padding: 0px 18px;
            &.second {
                padding: 0 8px;
            }
        }
    }
    .limit-warning {
        font-size: 20px;
    }
}

@media only screen and (min-width: 2600px) {
    .submit-details {
        .modal-content {
            .modal-header {
                padding: 1rem 1rem;
                .back-btn {
                    height: 96px;
                    width: 96px;
                    font-size: 50px;
                }
                .title {
                    h2 {
                        font-size: 64px;
                    }
                }
            }
            .modal-body {
                margin-top: 10rem;
                .col-title {
                    margin-bottom: 160px;
                    font-size: 60px;
                }
                .reserver-btn {
                    font-size: 40px;
                    padding-top: 52px;
                    padding-bottom: 52px;
                    border-radius: 46.634px;
                }
            }
        }
    }
    .tab-sec-main {
        >.nav {
            padding: 38px 20px;
            border-width: 3px;
            >.nav-item {
                >.nav-link {
                    font-size: 40px;
                    padding: 1rem 2rem 1rem 0rem;
                }
            }
        }
        >.tab-content {
            padding: 58px 20px;
        }
    }
    .tab-sec-sub {
        >.nav {
            padding: 0px 0px 34px 0px;
            >.nav-item {
                >.nav-link {
                    font-size: 36px;
                    padding: 12px 0;
                    margin-right: 40px;
                    border-width: 4px;
                }
            }
        }
        >.tab-content {
            padding: 60px 0px;
        }
    }
    .single-person {
        border-radius: 50px;
        border-width: 3px;
        padding: 46px 40px;
        .left {
            .person-icon {
                font-size: 60px;
                margin-right: 18px;
            }
            .person-detail {
                .person-id {
                    font-size: 18px;
                    display: block;
                }
                .person-name {
                    font-size: 32px;
                }
            }
        }
        .right {
            .person-edit {
                font-size: 40px;
                // margin-right: 24px;
            }
            .person-close {
                font-size: 40px;
            }
        }
    }
    .payment-points {
        .points {
            margin-bottom: 72px;
            .points-top {
                margin-bottom: 16px;
                .points-icon {
                    font-size: 40px;
                    margin-right: 20px;
                }
                .text {
                    font-size: 38px;
                }
            }
            .points-bottom {
                .text {
                    font-size: 32px;
                }
            }
        }
    }
    .limit-warning {
        font-size: 40px;
    }
}