.timer{
    color: #606060;
    border: 2px solid #606060;
    border-radius: 100px;
    display: flex;
    align-items: center;
    max-width: -moz-fit-content;
    max-width: fit-content;
    padding: 16px 20px;
    background-color: #ffffff;
    .icon {
        margin-right: 10px;
        color: inherit;
        font-size: 20px;
    }
    .time {
        margin: 0;
        color: inherit;
        font-size: 20px;
    }
    &.wood {
        color: #E78885;
        border-color: #E78885;
    }
    &.light-pink {
        color: #E58888;
        border-color: #E58888;
    }
    &.blue {
        color: #76AED3;
        border-color: #76AED3;
    }
    &.green {
        color: #A2C691;
        border-color: #A2C691;
    }
}

.area-map-timer {
    margin-right: 12px;
    padding: 8px 12px;
}

@media only screen and (min-width: 768px) {
    
}

@media only screen and (min-width: 2200px) {
    .timer{
        border-width: 4px;
        border-radius: 100px;
        padding: 32px 40px;
        .icon {
            margin-right: 20px;
            font-size: 40px;
        }
        .time {
            font-size: 40px;
        }
    }
}