@import url(./helper.scss);
@import url(./variable.scss);
@import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600;700&display=swap");

@font-face {
  font-family: "Spartan Medium";
  src: url("../fonts/spartan-medium/spartan-medium.eot");
  src: url("../fonts/spartan-medium/spartan-medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/spartan-medium/spartan-medium.woff") format("woff"),
    url("../fonts/spartan-medium/spartan-medium.woff2") format("woff2"),
    url("../fonts/spartan-medium/spartan-medium.ttf") format("truetype"),
    url("../fonts/spartan-medium/spartan-medium.svg#Spartan Medium") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
html {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

body {
  font-family: "Readex Pro", sans-serif;
  color: #11151e;
  margin: 0px;
  height: 100%;
  // position: fixed;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
#root {
  height: 100%;
  width: 100%;
}
.header-logo {
  position: absolute;
  z-index: 100;
  top: 0;
}

.map-letter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 71px;
  height: 71px;
  border-radius: 35.5px;
  padding-bottom: 5px;
  color: #fff;
  background-color: black;
  font-size: 30px;
  position: "absolute";
  .chat-badge {
    position: absolute;
    top: -8px;
    right: 0px;
    padding: 6px 8px;
    border-radius: 100%;
    color: black;
    background-color: #ffffff;
    font-size: 12px;
  }
}

.st-gabriel-map-letter {
  background-color: #163832;
  font-size: 30px !important;
  color: #fff;
  padding: 0px 5px;
  line-height: 30px;
}

.st-come-map-letter {
  width: 128px !important;
  height: 55px !important;
  border-radius: 0 !important;
  font-size: 35px !important;
}

.phase-2-map-letter {
  border-radius: 1px;
  font-size: 18px;
  height: 23px;
  width: 55px;
  padding: 0;
}

.phase-3-map-letter {
  border-radius: 1px;
  font-size: 11px;
  height: 15px;
  width: 35px;
  padding: 0;
}

.phase-4-map-letter {
  // border-radius: 1px;
  font-family: "Spartan Medium";
  font-size: 12px;
  // font-family: sans-serif;
  background-color: black;
  color: #fff;
  // height: 25px;
  // width: 62px;
  padding: 2px 2px 0px;
  line-height: 12px;
}

.phase-4-map-dome-letter{
  font-family: "Spartan Medium";
  font-size: 10px;
  background-color: black;
  color: #fff;
  padding: 2px 2px 0px;
  line-height: 10px;
}

.phase-4-nord-map-letter {
  // border-radius: 1px;
  font-family: "Spartan Medium";
  font-size: 20px;
  // font-family: sans-serif;
  background-color: black;
  color: #fff;
  // height: 25px;
  // width: 62px;
  padding: 5px 4px 0px;
  line-height: 20px;
}

.phasr4areamap {
  background-color:rgb(149 166 136 / 50%);
  height: 100vh;
}
.map-center {
  height: 100%;
  width: 100%;
  > div {
    height: 100%;
    > div {
      height: 100%;
      > div {
        height: 100%;
        display: flex !important;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.stmap-letter {
  background-color: white !important;
  width: 63px !important;
  height: 63px !important;
  padding-bottom: 0 !important;
  color: black !important;
  font-size: 38px !important;
}

.st-come-main-map {
  // background-image: url(../images/background.png);
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.event-modal1 {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  overflow: auto;
}

.event-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background-color: #00000080;
  .event-dialog {
    max-width: 500px;
    margin: 0.5rem;
    min-height: calc(100% - 3.5rem);
    display: flex;
    align-items: center;
    .event-content {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      pointer-events: auto;
      background-color: #fff;
      background-clip: padding-box;
      outline: 0;
    }
    .eds-bg-color--background {
      border-radius: 0.3rem;
    }
  }
}
.selection-sec {
  min-height: 100vh;
  padding: 0 30px 50px;
  .selection-content {
    border-bottom: 9px solid #C4C4C4;
    .selection-heading {
      font-size: 16px;
      margin-bottom: 40px;
    }
    .selection-logo-sec {
      margin: 0 auto;
      gap: 40px;
      .st-come-card {
        box-shadow: 0px 4px 13.2px 0px #00000040;
        background-color: #F3F3F3;
        border-radius: 177px;
        height: 150px;
        width: 70%;
        margin: auto;
        padding: 24px 40px;
        img {
          max-width: 564px;
        }
        &.domain-pine-hill-logo {
          img {
            max-width: 772px;
          }
        }
      }
    }
  }
}
.great-map-sec {
  background-color: #d5e7d5;
  .welcome-sec {
    .welcome-sec-logo {
      border-radius: 0px 0px 21.21px 21.21px;
      box-shadow: 0px 14.14px 61.85px 0px #00000029;
      .head-logo {
        max-width: 140px;
      }
    }
    h6 {
      color: #8EA385;
      padding-top: 52px;
      @media only screen and (max-width: 575.98px) {
        padding-top: 29px;
      }
    }
    p {
      color: #222222;
      font-size: 14px;
    }
  }
}

.map-area-img {
  overflow-x: auto;
}

@media only screen and (max-width: 575px) {
  .map-area-img {
    img {
      transform: translateY(-20%);
    }
  }
}
@media only screen and (min-width: 576px) {
  .event-modal {
    .event-dialog {
      margin: 1.75rem auto;
    }
  }
  .selection-sec {
    padding: 0 40px 60px;
    .selection-content {
      .selection-heading {
        font-size: 24px;
        margin-bottom: 50px;
      }
    }
  }
  .map-area-img {
    img {
      position: relative;
      left: 50%;
      right: 0;
      transform: translate(-50%, 0px);
    }
  }
}

@media only screen and (min-width: 768px) {
  .selection-sec {
    height: 100%;
    padding: 0 40px 60px;
    .selection-content {
      .selection-heading {
        font-size: 34px;
        margin-bottom: 80px;
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .selection-sec {
    padding: 0 50px 80px;
    .selection-content {
      .selection-heading {
        font-size: 34px;
        margin-bottom: 100px;
      }
      .selection-logo-sec {
        gap: 40px;
        .st-come-card {
          height: 158px;
          width: 33%;
          margin: 0;
          padding: 24px 40px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .selection-sec {
    padding: 0 60px 30px;
    .selection-content {
      .selection-heading {
        font-size: 34px;
        margin-bottom: 126px;
      }
      .selection-logo-sec {
        gap: 70px;
        .st-come-card {
          height: 189px;
          width: 25%;
          margin: 0;
          padding: 0px 50px;
        }
      }
    }
  }
}
@media only screen and (min-width: 1600px) {
  .selection-sec {
    padding: 0 60px 60px;
    .selection-content {
      .selection-logo-sec {
        .st-come-card {
          padding: 0px 100px;
        }
      }
    }
  }
}

@media only screen and (min-width: 2400px) {
  .selection-sec {
    padding: 0 60px 160px;
    .selection-content {
      .selection-logo-sec {
        .st-come-card {
          height: 389px;
          padding: 0px 120px;
        }
      }
    }
  }
}

@media only screen and (min-width: 3200px) {
  .selection-sec {
    padding: 0 60px 160px;
    .selection-content {
      .selection-logo-sec {
        .st-come-card {
          height: 489px;
          padding: 0px 150px;
        }
      }
    }
  }
}
