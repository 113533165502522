.about-details {
    .modal-header {
        border-bottom: none;
    }
    .modal-body {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        .about-center {
            padding: 2rem 0;
            .text {
                color: #11151E;
                margin-bottom: 24px;
            }
            .title {
                color: #708468;
                max-width: 340px;
                margin-left: auto;
                margin-right: auto;
            }
            .about-button {
                font-size: 20px;
                font-weight: 500;
                padding-top: 1rem;
                padding-bottom: 1rem;
                border-radius: 28px;
            }
        }
    }
    .about-divider {
        border: 1px dashed #E4E4E4;
        margin: 40px 0;
    }
}

@media only screen and (min-width: 2600px) {
    .about-details {
        .modal-body {
            .about-center {
                .text {
                    font-size: 32px;
                    margin-bottom: 48px;
                }
                .title {
                    max-width: 680px;
                    font-size: 56px;
                }
                .about-button {
                    font-size: 36px;
                    padding-top: 2rem;
                    padding-bottom: 2rem;
                    border-radius: 40px;
                }
            }
        }
        .about-divider {
            border-width: 4px;
            margin: 80px 0;
        }
    }
}