.backdrop {
  background-color: #000000;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 11 !important;
  top: 0; // opacity: 0.8;
  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
