.information {
    .modal-body {
        padding-top: 30px;
        padding-bottom: 30px;
        &.desktop {
            .info-header {
                .title {
                    margin-left: 20px;
                }
            }
            .info-bottom {
                margin-top: 100px;
                margin-bottom: 100px;
                .info-left {
                    .col-title {
                        margin-bottom: 3rem;
                        font-weight: 600;
                    }
                    .info-group {
                        margin-bottom: 50px;
                    }
                }
                .info-center {
                    border-left: 3px dashed #c1c1c1;
                }
                .info-right {
                    .col-title {
                        margin-bottom: 3rem;
                        font-weight: 600;
                    }
                    .terrain-map {
                        position: relative;
                        max-width: 800px;
                        .progress-line {
                            position: relative;
                            z-index: 1;
                        }
                        .btn-1 {
                            position: absolute;
                            left: 0;
                            z-index: 2;
                            bottom: 0;
                            transform: translate(-50%, 50%);
                        }
                        .btn-2 {
                            position: absolute;
                            left: 50%;
                            z-index: 2;
                            top: 0;
                            transform: translate(-50%, -50%);
                        }
                        .btn-3 {
                            position: absolute;
                            right: 0;
                            z-index: 2;
                            bottom: 0px;
                            transform: translate(50%, 50%);
                        }
                        .btn-1, .btn-2, .btn-3 {
                            cursor: auto;
                            pointer-events: none;
                        }
                        .terrain-map-img {
                            position: absolute;
                            left: 50%;
                            top: 70%;
                            max-width: 60%;
                            svg {
                                width: 80%;
                            }
                        }
                    }
                }
            }
            .info-footer {
                .btn-chat {
                    height: 60px;
                    width: 60px;
                    font-size: 26px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
        &.mobile {
            overflow-x: hidden;
            .info-bottom {
                .nav {
                    .nav-item {
                        padding-right: 22px;
                        .nav-link {
                            padding: 0 0 6px 0;
                            color: #c1c1c1;
                            border-bottom: 2px solid transparent;
                            &.active {
                                border-color: #ce785e;
                            }
                        }
                    }
                }
                .tab-content {
                    padding: 40px 0 0 0;
                    position: relative;
                    .tab-pane {
                        padding-bottom: 100px;
                        .info-group {
                            margin-bottom: 30px;
                        }
                        .terrain-map {
                            margin-bottom: 50%;
                            position: relative;
                            .progress-line {
                                position: relative;
                                z-index: 1;
                            }
                            .btn-1 {
                                position: absolute;
                                left: 0;
                                z-index: 2;
                                bottom: 0;
                                transform: translate(-50%, 50%);
                            }
                            .btn-2 {
                                position: absolute;
                                left: 50%;
                                z-index: 2;
                                top: 0;
                                transform: translate(-50%, -50%);
                            }
                            .btn-3 {
                                position: absolute;
                                right: 0;
                                z-index: 2;
                                bottom: 0px;
                                transform: translate(50%, 50%);
                            }
                            .terrain-map-img {
                                position: absolute;
                                left: 50%;
                                top: 70%;
                                max-width: 60%;
                                svg {
                                    width: 70%;
                                }
                            }
                        }
                        .imformation-footer {
                            position: fixed;
                            bottom: 0;
                            padding: 15px;
                            width: 100%;
                            z-index: 10;
                            background-color: #fff;
                            left: 0;
                            right: 0;
                        }
                    }
                }
            }
        }
    }
    .modal-footer {
        border: none;
        padding-top: 2rem;
        padding-bottom: 2rem;
        >* {
            margin: auto;
        }
        .reserver-btn, .plan-visit-btn {
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
            cursor: pointer;
        }
    }
}

.btn-4 {
    position: absolute;
    left: 50%;
    z-index: 2;
    bottom: -50%;
    transform: translate(-50%, 50%);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    height: 100px;
    width: 100px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 6%;
    img {
        width: 100%;
    }
    .n {
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        width: 30px;
        background: #FFFFFF;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        font-weight: 600;
        font-size: 11.2941px;
        color: #E78885;
        padding: 0;
        position: absolute;
        z-index: 10;
        right: 50%;
        transform: translate(50%, -50%);
        top: 0;
    }
    .e {
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        width: 30px;
        background: #FFFFFF;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        font-weight: 600;
        font-size: 11.2941px;
        color: #E78885;
        padding: 0;
        position: absolute;
        z-index: 10;
        right: 0;
        transform: translate(50%, -50%);
        top: 50%;
    }
    .s {
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        width: 30px;
        background: #FFFFFF;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        font-weight: 600;
        font-size: 11.2941px;
        color: #E78885;
        padding: 0;
        position: absolute;
        z-index: 10;
        right: 50%;
        transform: translate(50%, 50%);
        bottom: 0;
    }
    .w {
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        width: 30px;
        background: #FFFFFF;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        font-weight: 600;
        font-size: 11.2941px;
        color: #E78885;
        padding: 0;
        position: absolute;
        z-index: 10;
        left: 0;
        transform: translate(-50%, -50%);
        top: 50%;
    }
}

.info-group {
    &:last-child {
        margin-bottom: 0px;
    }
    .info-title {
        font-weight: 400;
        margin-bottom: 13px;
    }
    .info-text {
        color: #c1c1c1;
        font-weight: 300;
    }
    .info-sign {
        font-weight: normal;
    }
}

@media only screen and (min-width: 2600px) {
    .information {
        .modal-body {
            padding-top: 90px;
            padding-bottom: 90px;
            &.desktop {
                .info-header {
                    .title {
                        margin-left: 60px;
                        h2 {
                            font-size: 64px;
                        }
                        h6 {
                            font-size: 24px;
                        }
                    }
                }
                .info-bottom {
                    margin-top: 250px;
                    .info-left {
                        .col-title {
                            font-size: 46px;
                            margin-bottom: 80px;
                        }
                    }
                    .info-right {
                        .col-title {
                            font-size: 46px;
                            margin-bottom: 80px;
                        }
                    }
                }
            }
        }
        .modal-footer {
            padding-top: 4rem;
            padding-bottom: 4rem;
            .reserver-btn, .plan-visit-btn {
                font-size: 40px;
                padding-top: 40px;
                padding-bottom: 40px;
            }
            .btn-chat {
                height: 150px;
                width: 150px;
                font-size: 60px;
            }
        }
    }
    .info-group {
        .info-title {
            font-size: 36px;
        }
        .info-text {
            font-size: 40px;
        }
        .info-sign {
            font-size: 40px;
        }
    }
    .btn-4 {
        height: 200px;
        width: 200px;
        .n {
            height: 60px;
            width: 60px;
            font-size: 20px;
        }
        .e {
            height: 60px;
            width: 60px;
            font-size: 20px;
        }
        .s {
            height: 60px;
            width: 60px;
            font-size: 20px;
        }
        .w {
            height: 60px;
            width: 60px;
            font-size: 20px;
        }
    }
}
@media only screen and (max-width: 765px) {
    .information {
        .modal-body.mobile {
            .info-bottom {
                .tab-content {
                    .tab-pane {
                        .terrain-map {
                            max-width: calc(100% - 75px);
                            margin-left: auto;
                            margin-right: auto;
                        }
                    }
                }
            }
        }
    }
}