.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 10;
    align-items: center;
    background-color: transparent;
    padding: 16px 15px;
    opacity: 1;
    transition: opacity 250ms;
    &.hide {
        opacity: 0;
    }
    .back {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #CE785E;
        font-size: 36px;
        box-shadow: 0px 0px 54.6px rgba(0, 0, 0, 0.2);
    }
    .logo {
        max-width: 210px;
        padding: 20px 50px;
        background: #fff;
        border-radius: 0 0 10px 10px;
        box-shadow: 0px 0px 39.7651px rgba(0, 0, 0, 0.2);
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        top: 0;
    }
    .header-right {
        margin-left: auto;
    }
    .toggle {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #11151E;
        font-size: 100%;
        box-shadow: 0px 0px 54.6px rgba(0, 0, 0, 0.2);
    }
}

.header-menu {
    margin-right: 12px;
    &:last-child {
        margin-right: 0px;
    }
    .header-menu-btn {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 54.6px rgba(0, 0, 0, 0.2);
        font-size: 36px;
    }
    .dropdown-menu {
        border-radius: 10px;
        background: #FFFFFF;
        overflow: hidden;
        inset: 0px 0px auto auto !important;
        box-shadow: 0px 0px 67.5422px rgba(0, 0, 0, 0.2);
        // .header-compass-menu {
        //     padding: 20px;
        //     min-width: 400px;
        // }
        hr {
            margin: 0.5rem 1rem;
            background-color: #F1F1F1;
            opacity: 1;
        }
        .dropdown-item {
            padding-top: 7px;
            padding-bottom: 7px;
            cursor: pointer;
            .menu-icon-cover {
                min-width: 30px;
                display: inline-block;
                margin-right: 14px;
                text-align: center;
                .menu-icon {
                    filter: brightness(0) saturate(100%) invert(61%) sepia(19%) saturate(1253%) hue-rotate(327deg) brightness(86%) contrast(85%);
                }
            }
        }
        &:active {
            text-decoration: none;
            background-color: #FFFFFF;
        }
        .headerCompass {
            // padding: 20px;
            text-align: center;
        }
        // .header-compass {
        //     position: relative;
        //     text-align: center;
        //     .progress-circle {
        //         position: relative;
        //         z-index: 1;
        //     }
        //     .compass-controll {
        //         position: absolute;
        //         z-index: 2;
        //         left: 0;
        //         right: 0;
        //         top: 0;
        //         bottom: 0;
        //         height: 100%;
        //         width: 100%;
        //         .btn-4 {
        //             transform: translate(50%, 50%) !important;
        //             padding: 5px;
        //             right: 50%;
        //             bottom: 0;
        //             position: absolute;
        //         }
        //     }
        //     .progress-circle-img {
        //         position: absolute;
        //         left: 50%;
        //         top: 15%;
        //         max-width: 60%;
        //         z-index: 3;
        //         transform: translate(-50%, 50%);
        //     }
        // }
    }
}

@media only screen and (max-width: 599px) {
    .header-menu {
        .dropdown-menu {
            .header-compass-menu {
                min-width: 75vw;
            }
        }
    }
}

@media only screen and (min-width: 600px) {}

@media only screen and (min-width: 768px) {}

@media only screen and (min-width: 992px) {}

@media only screen and (min-width: 1200px) {}

@media only screen and (min-width: 1600px) {
    .header {
        padding: 42px 15px;
        .back {
            width: 60px;
            height: 60px;
            font-size: 40px;
        }
        .logo {
            max-width: 300px;
            padding: 40px 60px 40px 60px;
            border-radius: 0px 0px 30px 30px;
        }
    }
    .header-menu {
        margin-right: 30px;
        .header-menu-btn {
            width: 60px;
            height: 60px;
            font-size: 40px;
        }
    }
}

@media only screen and (min-width: 2600px) {
    .header {
        padding: 64px 15px;
        .back {
            width: 96px;
            height: 96px;
            font-size: 50px;
        }
        .logo {
            max-width: 630px;
            padding: 50px 150px 60px 150px;
            border-radius: 0px 0px 53.0201px 53.0201px;
        }
    }
    .header-menu {
        margin-right: 44px;
        .header-menu-btn {
            width: 96px;
            height: 96px;
            font-size: 50px;
        }
    }
}