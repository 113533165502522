.contact-card {
  text-align: center;
  background: #ffffff;
  box-shadow: 0px 0px 66.751px rgba(0, 0, 0, 0.12);
  border-radius: 51.25px;
  border: none;
  margin: 16px 0;
  .card-body {
    padding: 50px 0;
    .contact-icon {
      color: #ce785e;
      margin-bottom: 20px;
      font-size: 40px;
    }
    p {
      margin: 0;
    }
  }
}

.menu-tv a:link,
a:visited,
a:visited:active,
a:hover {
  // color: #11151e;
  text-decoration: none !important;
  cursor: auto;
}

.modal-header {
  border-bottom: 2px dashed #f0f0f0;
}

.about-card {
  border: none;
  background-color: transparent;
  .card-body {
    text-align: center;
  }
}

.mobile-menu {
  .nav-link {
    padding: 20px 10px;
    .contact-icon {
      color: #ce785e;
      margin-right: 16px;
      font-size: 24px;
    }
    span {
      font-size: 18px;
    }
  }
}

.about-center-desktop {
  margin: 6vh 0;
}

@media only screen and (min-width: 576px) {
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 992px) {
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1600px) {
}

@media only screen and (min-width: 2400px) {
}

@media only screen and (min-width: 3200px) {
}
