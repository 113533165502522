.g-condition {
    opacity: 1;
    transition: opacity 250ms;
    &.hide {
        opacity: 0;
    }
    &.desktop {
        list-style-type: none;
        padding: 20px;
        margin: 0;
        border-radius: 10px;
        background-color: #ffffff;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
        position: fixed;
        z-index: 10;
        bottom: 5%;
        right: 14px;
        .main {
            font-weight: 500;
            color: #11151E;
            margin-bottom: 20px;
        }
        .item {
            display: flex;
            margin-bottom: 16px;
            align-items: center;
            color: #C1C1C1;
            .item-box {
                height: 20px;
                width: 36px;
                margin-right: 12px;
                background-color: #11151E;
                &.first {
                    background-color: #8EA286;
                }
                &.second {
                    background-color: #E5C59C;
                }
                &.third {
                    background-color: #E78885;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &.mobile {
        padding: 10px 20px;
        margin: 0;
        border-radius: 25px;
        background-color: #ffffff;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
        position: fixed;
        z-index: 10;
        bottom: 2%;
        left: 5%;
        right: 5%;
        max-width: 90%;
        .item {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            margin: 20px 0 0 0;
            color: #C1C1C1;
            font-size: 14px;
            .item-box {
                display: block;
                height: 14px;
                width: 22px;
                margin-right: 12px;
                background-color: #11151E;
                &.first {
                    background-color: #8EA286;
                }
                &.second {
                    background-color: #E5C59C;
                }
                &.third {
                    background-color: #E78885;
                }
            }
        }
    }
}

@media only screen and (max-width: 599px) {
    .g-condition {
        &.mobile {
            .item {
                margin: 20px 0 0 0;
                display: block;
                text-align: center;
                .item-box {
                    margin: 0 auto 10px auto;
                }
            }
        }
    }
}

@media only screen and (min-width: 2600px) {
    .g-condition {
        &.desktop {
            padding: 44px;
            margin: 0;
            border-radius: 25px;
            .main {
                margin-bottom: 40px;
                font-size: 30px;
            }
            .item {
                margin-bottom: 60px;
                font-size: 30px;
                .item-box {
                    height: 32px;
                    width: 52px;
                    margin-right: 28px;
                }
            }
        }
    }
}