.signup-form {
  .signup-form-nav {
    // margin-left: -1.5rem;
    // margin-right: -1.5rem;
    overflow-x: auto;
    flex-wrap: nowrap;
    .nav-item {
      flex: none;
      .nav-link {
        color: #c1c1c1;
        cursor: pointer;
        &.active {
          color: #567633;
        }
      }
    }
  }
  .signup-form-tab {
    .form-control {
      text-align: center;
    }
  }
}
.btn-register-following {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  color: #8ea286;
  padding: 22px 104px;
  border-radius: 25px;
  font-size: 18px;
  &.active {
    background-color: #8ea286;
    color: #ffffff;
    border-color: #8ea286;
  }
  &:hover {
    background-color: #8ea286;
    color: #ffffff;
    border-color: #8ea286;
  }
}

@media only screen and (min-width: 2200px) {
  .login {
    .modal-dialog {
      max-width: 714px;
      .modal-content {
        padding: 48px 45px;
        border-radius: 40px;
      }
      .modal-header {
        padding: 0 0 60px 0;
        border-width: 4.5px;
      }
      .modal-body {
        padding: 30px 0 40px 0;
      }
      .modal-footer {
        .view-more-btn {
          font-size: 40px;
          padding-top: 50px;
          padding-bottom: 50px;
          border-radius: 50px;
        }
      }
    }
  }
}