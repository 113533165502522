a {
    color: #11151e;
}

// Color
.green {
    color: #8ea286 !important;
}

.black {
    color: #11151e;
}

.grey {
    color: #c1c1c1 !important;
}

.grey-b1b1b1 {
    color: #b1b1b1;
}

.orange {
    color: #ce785e;
}

.orange-2 {
    color: #b28869;
}

.light-orange {
    color: #e5c59c;
}

.light-green {
    color: #8ea286;
}

.dark-orange {
    color: #e58888;
}

// BG Color
.bg-green {
    background-color: #8EA286;
}

// Font Size
.fs-12 {
    font-size: 12px;
}

.fs-14 {
    font-size: 14px;
}

.fs-15 {
    font-size: 15px;
}

.fs-16 {
    font-size: 16px;
}

.fs-18 {
    font-size: 18px;
}

.fs-20 {
    font-size: 20px;
}

.fs-22 {
    font-size: 22px;
}

.fs-24 {
    font-size: 24px;
}

.fs-26 {
    font-size: 26px;
}

.fs-28 {
    font-size: 28px;
}

// Line Height
.lh-19 {
    line-height: 19px;
}

// Font Weight
.fw-600 {
    font-weight: 600;
}

// Button Start
.btn-white {
    color: #ce785e;
    background-color: #ffffff;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    &:hover {
        color: #ffffff;
        background-color: #ce785e;
    }
    &:focus {
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    }
}

.btn-dark {
    color: #000000;
    background-color: #ffffff;
    border: none;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    &:hover {
        color: #000000;
        background-color: #ffffff;
    }
    &:focus {
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
        color: #000000;
        background-color: #ffffff;
    }
}

.btn-grey {
    color: #ffffff;
    background-color: #d0d5dd;
    box-shadow: 0px 0px 68.2658px rgba(0, 0, 0, 0.1);
    &:hover {
        color: #d0d5dd;
        background-color: #ffffff;
    }
    &:focus {
        box-shadow: 0px 0px 68.2658px rgba(0, 0, 0, 0.1);
    }
}

.btn-light-orange {
    color: #ffffff;
    background-color: #e5c59c;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    &:hover {
        color: #e5c59c;
        background-color: #ffffff;
    }
    &:focus {
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    }
}

.btn-light-green {
    color: #ffffff;
    background-color: #8ea286;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    &:hover {
        color: #8ea286;
        background-color: #ffffff;
    }
    &:focus {
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    }
}

.btn-white-black {
    color: #11151e;
    background-color: #ffffff;
    &.active {
        background-color: #ce785e;
        color: #ffffff;
    }
    &:focus {
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    }
}

.btn-white-green {
    color: #708468;
    background-color: #ffffff;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    &:hover {
        color: #ffffff;
        background-color: #708468;
    }
    &:focus {
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    }
}

.btn-green {
    color: #ffffff;
    background-color: #708468;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    &:hover {
        color: #708468;
        background-color: #ffffff;
    }
    &:focus {
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    }
}

.btn-green-2 {
    color: #ffffff;
    background-color: #8ea385;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    &:hover {
        color: #8ea385;
        background-color: #ffffff;
    }
    &:focus {
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    }
}

.btn-outline-green {
    color: #708468;
    background-color: #ffffff;
    border: 1px dashed #708468;
    &:hover {
        color: #ffffff;
        background-color: #708468;
    }
    &:focus {
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    }
}

.dropdown {
    .btn-white-black {
        color: #11151e;
        background-color: #ffffff;
        &:focus {
            box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
        }
    }
    &.show {
        .btn-white-black {
            background-color: #ce785e;
            color: #ffffff;
            &:focus {
                box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
            }
        }
    }
}

.btn-circle {
    height: 56px;
    width: 56px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.btn-big-circle {
    height: 100px;
    width: 100px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 60px;
}

.bg-viewmore-progress {
    background-color: #ce785e;
}

.bg-ground-progress {
    background-color: #e78885;
}

// Curesor
.cursor-pointer {
    cursor: pointer;
}

.dropdown-toggle::after {
    display: none;
}

// Form Start
.form-group {
    padding-bottom: 24px;
    .form-label {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 10px;
    }
    .form-control {
        padding: 0px 10px 16px 0;
        font-size: 18px;
        border-bottom: 1px solid #c1c1c1;
        border-left: none;
        border-right: none;
        border-top: none;
        font-weight: 300;
        border-radius: 0;
        &:focus {
            box-shadow: none;
            border-color: #11151e;
        }
        &::placeholder {
            color: #c1c1c1;
        }
        &.form-select {
            background-image: url(../images/arrow-down.svg);
            background-size: 18px 10px;
        }
        &.form-select-second {
            background-image: url(../images/arrow-down-green.svg) !important;
            background-position: right 0 center;
            color: #c1c1c1;
            option {
                color: #000000;
            }
        }
    }
    &.b-bottom {
        border-bottom: 2px dashed #f0f0f0;
        margin-bottom: 30px;
    }
}
.custom-form-control {
    position: relative;
    .PhoneInputCountry {
        position: absolute;
        top: 6px;
        left: 0;
    }
    .PhoneInputInput {
        display: block;
        width: 100%;
        line-height: 1.5;
        color: #212529;
        background-color: #fff;
        background-clip: padding-box;
        appearance: none;
        transition:
            border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;

        padding: 0px 10px 16px 0;
        font-size: 18px;
        border-bottom: 1px solid #c1c1c1;
        border-left: none;
        border-right: none;
        border-top: none;
        font-weight: 300;
        border-radius: 0;
        outline: none;
        text-align: center;
        &:focus-visible {
            border-top: none;
            border-left: none;
            border-right: none;
            border-bottom-color: #11151e;
        }
    }
}
.select-holder {
    position: relative;
    select {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 60%;
        opacity: 0;
    }
    .js-fake-input {
        font-weight: 300;
        text-align: center;
        width: 100%;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 1px solid #c1c1c1;
        padding: 0px 10px 16px 0;
        background-image: url(../../assets/images/arrow-down-green.svg);
        background-position: right 0 center;
        background-repeat: no-repeat;
        background-size: 18px 10px;
        font-size: 18px;
    }
}
textarea {
    border: 1px solid #c1c1c1 !important;
    padding: 14px !important;
    resize: none;
}

.item-ground {
    margin: 10px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .progress {
        width: 80%;
        height: 0.6rem;
    }
    span {
        font-size: 18px;
        color: #e58888;
        font-weight: 500;
        font-style: normal;
    }
}

.pac-container {
    z-index: 9999;
}

@media only screen and (min-width: 768px) {
    // Form Start
    .form-group {
        padding-bottom: 30px;
        .form-label {
            margin-bottom: 18px;
        }
        &.b-bottom {
            margin-bottom: 30px;
        }
    }
}

@media only screen and (min-width: 1600px) {
    .container {
        max-width: 1520px;
    }
}

@media only screen and (min-width: 1800px) {
    .container {
        max-width: 1720px;
    }
}

@media only screen and (min-width: 2000px) {
    .container {
        max-width: 1920px;
    }
}

@media only screen and (min-width: 2200px) {
    .container {
        max-width: 2120px;
    }
}

@media only screen and (min-width: 2600px) {
    .btn-circle {
        height: 96px;
        width: 96px;
        font-size: 40px;
        font-weight: 800;
    }
    // Form Start
    .form-group {
        padding-bottom: 106px;
        .form-label {
            font-size: 50px;
            margin-bottom: 50px;
        }
        .form-control {
            padding: 0px 16px 35px 0;
            font-size: 40px;
            &.form-select {
                background-size: 36px 20px;
            }
        }
        &.b-bottom {
            border-bottom: 2.06px dashed #c1c1c1;
            margin-bottom: 96px;
        }
    }
    .select-holder {
        .js-fake-input {
            padding: 0px 16px 35px 0;
            font-size: 40px;
            background-size: 16px 10px;
        }
    }
    textarea {
        border: 5px solid #c1c1c1 !important;
        padding: 50px !important;
    }
    .custom-form-control {
        .PhoneInputInput {
            padding: 0px 16px 35px 0;
            font-size: 40px;
        }
    }
}
