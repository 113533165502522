.promises {
    .modal-header {
        border-bottom: none;
        .col-first {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .back-btn {
                color: #ce785e;
                font-size: 24px;
                background: #ffffff;
                border-radius: 50%;
                height: 50px;
                width: 50px;
                padding: 14px;
                cursor: pointer;
            }
        }
        .col-second {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .col-third {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .toggle-btn {
                font-size: 20px;
                color: #11151e;
                background: #ffffff;
                box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
                border-radius: 50%;
                height: 50px;
                width: 50px;
                padding: 14px;
                cursor: pointer;
            }
        }
    }
    .modal-body {
        .top {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: 30px;
        }
    }
}

.promises-single {
    border: none;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    background-color: #fff;
    overflow: hidden;
    margin-bottom: 10px;
    .card-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        border: none;
        padding-top: 30px;
        background-color: inherit;
        .item-name {
            margin-bottom: 0;
            font-size: 28px;
            span {
                font-size: 18px;
                font-weight: 300;
            }
        }
        .dimension {
            color: #11151e;
            font-size: 17px;
            font-weight: 300;
            margin: 0;
        }
    }
    .card-body {
        padding-top: 0;
        .card-bottom {
            margin: 30px 0 0 0;
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
            .status {
                display: flex;
                width: fit-content;
                padding: 16px 20px;
                border-radius: 35px;
                border: 2px solid #dedede;
                color: #dedede;
                align-items: center;
                font-weight: 500;
                font-size: 16px;
                &.in-process {
                    border-color: #E5C59C;
                    color: #E5C59C;
                }
                &.submitted {
                    border-color: #E58888;
                    color: #E58888;
                }
                &.reviewed {
                    border-color: #76AED3;
                    color: #76AED3;
                }
                &.accepted {
                    border-color: #A2C691;
                    color: #A2C691;
                }
            }
        }
        .modify-btn {
            width: 100%;
            padding-top: 22px;
            padding-bottom: 22px;
            border-radius: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            &.disable {
                background: #dedede;
            }
            svg {
                transform: scale(1.35);
            }
        }
    }
}

@media only screen and (min-width: 576px) {
    .promises {
        .modal-header {
            .col-first {
                .back-btn {
                    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
                }
            }
        }
    }
}

@media only screen and (min-width: 2600px) {
    .promises {
        .modal-header {
            .col-first {
                .back-btn {
                    font-size: 56px;
                    height: 80px;
                    width: 80px;
                    padding: 12px;
                }
            }
            .col-third {
                .toggle-btn {
                    font-size: 40px;
                    height: 80px;
                    width: 80px;
                    padding: 22px;
                }
            }
        }
        .modal-body {
            .top {
                margin-bottom: 60px;
                h5 {
                    font-size: 44px;
                }
                p {
                    font-size: 28px;
                }
            }
        }
    }
    .favourite-single {
        border-radius: 50px;
        margin-bottom: 20px;
        .card-header {
            padding-top: 60px;
            .item-name {
                margin-bottom: 0;
                font-size: 60px;
                span {
                    font-size: 30px;
                }
            }
            .dimension {
                font-size: 30px;
            }
        }
        .card-body {
            .card-bottom {
                margin: 46px 0;
                .status {
                    padding: 30px 40px;
                    border-radius: 50px;
                    border-width: 4px;
                    font-size: 30px;
                }
            }
            .modify-btn {
                font-size: 30px;
                padding-top: 40px;
                padding-bottom: 40px;
                border-radius: 40px;
            }
        }
    }
}