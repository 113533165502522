.spinner-filter {
    position: fixed;
    z-index: 5000;
    height: 100vh;
    width: 100vw;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.33);
}

.spin-loader {
    border: 16px solid #ffffff;
    border-top: 16px solid rgb(142, 163, 133);
    border-radius: 50%;
    width: 120px;
    height: 120px;
    position: fixed;
    top: calc(50% - 60px);
    left: calc(50% - 60px);
    z-index: 5001;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
