.register-form {
    text-align: center;
    .modal-content {
        .modal-header {
            padding: 0;
            border-bottom: 2.49963px dashed #F0F0F0;
            // min-height: 200px;
            .back-btn {
                align-items: center;
                background-color: #fff;
                border-radius: 50%;
                box-shadow: 0 0 54.6px rgba(0,0,0,.2);
                color: #ce785e;
                display: flex;
                font-size: 36px;
                height: 50px;
                width: 50px;
                justify-content: center;
            }
        }
        .modal-body {
            padding: 36px 1rem;
            .form-title {
                font-size: 18px;
                color: #B28869;
                margin-bottom: 40px;
            }
            .form-control {
                text-align: center;
            }
            .form-select {
                background-image: url(../../assets/images/arrow-down-green.svg);
            }
            .content {
                font-size: 16px;
                color: #C1C1C1;
                margin-bottom: 24px;
            }
            .button-first {
                width: 100%;
                color: #8EA286;
                text-decoration: none;
                margin-bottom: 54px;
            }
            .button-second {
                width: 100%;
                max-width: 500px;
                margin-bottom: 24px;
                border-radius: 25px;
                padding-top: 22px;
                padding-bottom: 22px;
                font-weight: 600;
                font-size: 18px;
            }
            .button-third {
                width: 100%;
                border-radius: 25px;
                font-size: 18px;
                text-decoration: none;
                color: #8EA286;
                span {
                    font-weight: 600;
                    color: #8EA286;
                    text-decoration: underline;
                }
            }
        }
    }
}

// @media only screen and (min-width: 768px) {
//     .register-form {
//         .modal-content {
//             .modal-header {
//                 min-height: 383px;
//             }
//         }
//     }
// }

@media only screen and (min-width: 2600px) {
    .register-form {
        .modal-content {
            .modal-header {
                border-width: 4px;
                .back-btn {
                    font-size: 50px;
                    height: 60px;
                    width: 60px;
                }
            }
            .modal-body {
                padding: 72px 2rem;
                .form-title {
                    font-size: 40px;
                    margin-bottom: 100px;
                }
                .content {
                    font-size: 30px;
                    margin-bottom: 60px;
                }
                .button-first {
                    font-size: 30px;
                    margin-bottom: 44px;
                }
                .button-second {
                    font-size: 30px;
                    margin-bottom: 44px;
                }
                .button-third {
                    font-size: 30px;
                }
            }
        }
    }
}

.error-message{
    color: red;
    font-size: 13px;
}