.login-form {
    text-align: center;
    .modal-content {
        .modal-header {
            padding: 1rem 1rem;
            border-bottom: 2.49963px dashed #F0F0F0;
            // min-height: 200px;
            .email-warning {
                background-color: #CE785E;
                padding: 16px 30px;
                color: #ffffff;
                position: absolute;
                top: 16px;
                left: 50%;
                right: auto;
                transform: translate(-50%, 0%);
                box-shadow: 0px 0px 30px rgba ( 0, 0, 0, 0.1);
                border-radius: 50px;
                width: max-content;
            }
        }
        .modal-body {
            padding: 36px 1rem;
            .form-title {
                font-size: 18px;
                color: #B28869;
                margin-bottom: 40px;
            }
            .form-control {
                text-align: center;
            }
            .button-first {
                width: 100%;
                color: #8EA286;
                text-decoration: none;
                margin-bottom: 54px;
            }
            .button-second {
                // margin-bottom: 24px;
                border-radius: 25px;
                padding-top: 22px;
                padding-bottom: 22px;
                font-weight: 600;
                font-size: 18px;
                width: 100%;
            }
            .reset-password {
                font-size: 18px;
                margin-bottom: 30px;
            }
            .reset-password span {
                cursor: pointer;
                color: #8EA286;
                text-decoration: underline;
            }
            .button-third {
                border-radius: 25px;
                padding-top: 22px;
                padding-bottom: 22px;
                font-weight: 600;
                font-size: 18px;
                width: 100%;
            }
        }
        .modal-footer {
            border-top: 2.49963px dashed #F0F0F0;
            .guest-link {
                color: #8EA286;
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .login-form {
        .modal-content {
            .modal-header {
                // min-height: 383px;
                .email-warning {
                    border-radius: 24px;
                    top: 20px;
                }
            }
            .modal-footer {
                padding-bottom: 3rem;
            }
        }
    }
}

@media only screen and (min-width: 2600px) {
    .login-form {
        .modal-content {
            .modal-header {
                border-width: 4px;
                .email-warning {
                    padding: 20px 40px;
                    top: 34px;
                    border-radius: 100px;
                    font-size: 30px;
                }
            }
            .modal-body {
                padding: 72px 2rem;
                .form-title {
                    font-size: 40px;
                    margin-bottom: 100px;
                }
                .button-first {
                    min-width: calc(50% - .5rem);
                    font-size: 30px;
                    // margin-bottom: 44px;
                }
                .button-second {
                    min-width: calc(50% - .5rem);
                    font-size: 30px;
                    // margin-bottom: 44px;
                }
                .button-third {
                    font-size: 30px;
                }
                .reset-password {
                    font-size: 18px;
                    margin-bottom: 30px;
                }
                .reset-password span{
                    color: #8EA286;
                    text-decoration: underline;
                }
            }
        }
    }
}