.new-login-modal {
    .new-loging-form {
        .form-divider {
            border-color: #ececec;
            opacity: 1;
        }
        .forgot-pwd-link {
            font-size: 14px;
            font-weight: normal;
            margin-bottom: 29px;
        }
    }
}

@media only screen and (max-width: 991px) {
    .new-login-modal {
        .new-loging-form {
            border-radius: 0px;
            border-left: none;
            border-right: none;
            border-bottom: none;
            border-color: #ECECEC;
        }
    }
}

@media only screen and (min-width: 992px) {
    .new-login-modal {
        .new-loging-form {
            margin-top: -46px;
            box-shadow: 0px 8px 35px 0px rgba(0, 0, 0, 0.16);
            border-color: transparent;
            .form-divider {
                margin-top: 40px;
                margin-bottom: 36px;
            }
        }
    }
}

@media only screen and (min-width: 1400px) {
    .new-login-modal {
        .new-loging-form {
            .form-divider {
                margin-top: 80px;
                margin-bottom: 74px;
            }
        }
    }
}
